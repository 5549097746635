import { ChainId, Token } from 'dragonpulse-finance-sdk'

export const CARROT: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x3B196427969452e3a4DE6D612B320343A5e91166', 18, 'CARROT', 'Carrot'),
}

export const USDC: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07', 6, 'USDC', 'USD Coin'),
}

export const WPULSE = new Token(ChainId.MAINNET, '0xA1077a294dDE1B09bB078844df40758a5D0f9a27', 18, 'WPULSE', 'Wrapped PULSE')

export const LOCK_TOKENS = ["0xc136E6B376a9946B156db1ED3A34b08AFdAeD76d"]

const tokens = {
  carrot: {
    symbol: 'CARROT',
    address: {
      369: '0x3B196427969452e3a4DE6D612B320343A5e91166',
    },
    decimals: 18,
    projectLink: 'https://carrotfinance.io/',
  },
  radish: {
    symbol: 'RADISH',
    address: {
      369: '0xD6aF2340E14d0E8616b7bE4182021A2876D1Bf1a',
    },
    decimals: 18,
    projectLink: 'https://carrotfinance.io/',
  },
  wpulse: {
    symbol: 'WPULSE',
    address: {
      369: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
    },
    decimals: 18,
    projectLink: 'https://pulse.org/',
  },
  pulse: {
    symbol: 'PULSE',
    address: {
      369: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
    },
    decimals: 18,
    projectLink: 'https://pulse.org/',
  },
  stela: {
    symbol: 'stPULSE',
    address: {
      369: '0x73695c1d15E0958bb5B0b3A107f32C364F138E7F'
    },
    decimals: 18,
    projectLink: 'https://carrotfinance.io'
  },
  // ethereum
  eth: {
    symbol: 'ETH',
    address: {
      369: '0x02DcdD04e3F455D838cd1249292C58f3B79e3C3C',
    },
    decimals: 18,
    projectLink: 'https://ethereum.org',
  },
  usdc: {
    symbol: 'USDC',
    address: {
      369: '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07',
    },
    decimals: 6,
    projectLink: 'https://www.circle.com/en/usdc',
  },
  mtrl: {
    symbol: 'MTRL',
    address: {
      369: '0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
    },
    decimals: 18,
    projectLink: 'https://material.network',
  },
  // binance
  bnb: {
    symbol: 'BNB',
    address: {
      369: '0x51B85F3889c7EA8f6d5EdEBFBadaDA0fDcE236c9',
    },
    decimals: 18,
    projectLink: 'https://www.binance.org/en/smartChain',
  },
  dai: {
    symbol: 'DAI',
    address: {
      369: '0xefD766cCb38EaF1dfd701853BFCe31359239F305',
    },
    decimals: 18,
    projectLink: 'https://www.circle.com/en/dai',
  },
  busd: {
    symbol: 'BUSD',
    address: {
      369: '0x9f1d0Ed4E041C503BD487E5dc9FC935Ab57F9a57',
    },
    decimals: 18,
    projectLink: 'https://paxos.com/busd/',
  },
  // heco
  ht: {
    symbol: 'HT',
    address: {
      369: '0xeceefC50f9aAcF0795586Ed90a8b9E24f55Ce3F3',
    },
    decimals: 18,
    projectLink: 'https://www.huobi.com/',
  },
  husd: {
    symbol: 'HUSD',
    address: {
      369: '0xF9Ca2eA3b1024c0DB31adB224B407441bECC18BB',
    },
    decimals: 8,
    projectLink: 'https://www.hecochain.com/',
  },
  htfilda: {
    symbol: 'htFILDA',
    address: {
      369: '0xB9Ae03e3320235D3a8AE537f87FF8529b445B590',
    },
    decimals: 18,
    projectLink: 'https://filda.io/',
  },
  // multi
  oelk: {
    symbol: 'ELK',
    address: {
      369: '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
    },
    decimals: 18,
    projectLink: 'https://elk.finance/',
  },
  elk: {
    symbol: 'ELK',
    address: {
      369: '0x8A953CfE442c5E8855cc6c61b1293FA648BAE472',
    },
    decimals: 18,
    projectLink: 'https://elk.finance/',
  },
  creda: {
    symbol: 'CREDA',
    address: {
      369: '0xc136E6B376a9946B156db1ED3A34b08AFdAeD76d',
    },
    decimals: 18,
    projectLink: 'https://creda.app/',
  },
  // Meme zone
  rinu: {
    symbol: 'RINU',
    address: {
      369: '0x0b0148b82631A1ce18c83048471716d591726977',
    },
    decimals: 9,
    projectLink: '',
  },
  rinuLP: {
    symbol: 'RINU-PULSE LP',
    address: {
      369: '0x411DA456D450111648310c5Ec3D188755727b92c',
    },
    decimals: 18,
    projectLink: '',
  },
  finu: {
    symbol: 'FINU',
    address: {
      369: '0x18521074aBA94FA730d06aAe000778Dcc8C6059d',
    },
    decimals: 9,
    projectLink: '',
  },
  finuLP: {
    symbol: 'FINU-PULSE LP',
    address: {
      369: '0xe8134F4e731Bb9a70Ddc0013755994B4906d480f',
    },
    decimals: 18,
    projectLink: '',
  },
  beer: {
    symbol: 'BEER',
    address: {
      369: '0x44F57DF34e7873191f5aF7065B8348dD581c34DC',
    },
    decimals: 18,
    projectLink: '',
  },
  beerLP: {
    symbol: 'BEER-PULSE LP',
    address: {
      369: '0xbcA320f6b57b422a7Bf98A5bFaf8635923998d41',
    },
    decimals: 18,
    projectLink: '',
  },
  sloth: {
    symbol: 'SLOTH',
    address: {
      369: '0xA70Efd687EADFb738389e0739a26fb9036D99A1E',
    },
    decimals: 18,
    projectLink: 'https://www.slothtoken.club/',
  },
  slothLP: {
    symbol: 'SLOTH-PULSE LP',
    address: {
      369: '0x3DCe3e9C2DBe687ab1794E89e48ad0947c4ae48F',
    },
    decimals: 18,
    projectLink: 'https://www.slothtoken.club/',
  },
  bun: {
    symbol: 'BUN',
    address: {
      369: '0x63B2dcd421d2E9168EC79e880fB0D2D45d539A66',
    },
    decimals: 18,
    projectLink: 'https://www.bunnypunk.online/',
  },
  bunLP: {
    symbol: 'BUN-PULSE LP',
    address: {
      369: '0xb454FC9F6681dCa0D7C3b7a170A89B8bD7bdA71A',
    },
    decimals: 18,
    projectLink: 'https://www.bunnypunk.online/',
  },
}

export default tokens
