export default {
  masterChef: {
    369: '0x9db412a73742136F9E4eE861175a4B15e77966b6',
  },
  swapRewardsChef: {
    369: '0xada41f0350DBDC8245A77948cC0f157ed3A4d8f2',
  },
  liquidStaking: {
    369: '0x7F5D64bd80e0d7d3835539437269808acf7997f1',
  },
   glide: {
    369: '0x3B196427969452e3a4DE6D612B320343A5e91166',
  },
  cakeVault: {
    369: '0xB910D12f2873775663B015ce1C05ac07514Cbf6E',
  },
  materialChef: {
    369: '0x473A20146f397c335D77b64A2cb2D773c1D391A1',
  },
  phantzPool: {
    369: '0x8991F7B2e66D2211bCDB1A1796f39A4699b8631a',
  },
  phantzV2Pool: {
    369: '0x6be4661405096e1dEEC1eD03250bB9d38aeA0804',
  },
  sousChef: {
    369: '0x9999999999999999999999999999999999999999',
  },
  lotteryV2: {
    369: '0x9999999999999999999999999999999999999999', // avoid ENS errors
  },
  multiCall: {
    // Multicall2
    1: '0x0555fcE4231A25AcB648284bfecf6B85e1AF6Bd4',
    369: '0x141240Abf0e7bE80cA59Ac0b656dcCd3318A70DB',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    128: '0x0555fcE4231A25AcB648284bfecf6B85e1AF6Bd4',
  },
  pancakeProfile: {
    369: '0x9999999999999999999999999999999999999999',
  },
  pancakeRabbits: {
    369: '0x9999999999999999999999999999999999999999',
  },
  bunnyFactory: {
    369: '0x9999999999999999999999999999999999999999',
  },
  claimRefund: {
    369: '0x9999999999999999999999999999999999999999',
  },
  pointCenterIfo: {
    369: '0x9999999999999999999999999999999999999999',
  },
  bunnySpecial: {
    369: '0x9999999999999999999999999999999999999999',
  },
  tradingCompetition: {
    369: '0x9999999999999999999999999999999999999999',
  },
  easterNft: {
    369: '0x9999999999999999999999999999999999999999',
  },
  predictions: {
    369: '0x9999999999999999999999999999999999999999',
  },
  chainlinkOracle: {
    369: '0x9999999999999999999999999999999999999999',
  },
  bunnySpecialCakeVault: {
    369: '0x9999999999999999999999999999999999999999',
  },
  bunnySpecialPrediction: {
    369: '0x9999999999999999999999999999999999999999',
  },
  farmAuction: {
    369: '0x9999999999999999999999999999999999999999',
  },
}
