import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.carrot,
    earningToken: tokens.carrot,
    contractAddress: {
      369: '0x9db412a73742136F9E4eE861175a4B15e77966b6',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.265',
    sortOrder: 1,
    isFinished: false,
  },
  {
    // SwapRewardsChef
    sousId: 1,
    stakingToken: tokens.carrot,
    earningToken: tokens.pulse,
    contractAddress: {
      369: '0xada41f0350DBDC8245A77948cC0f157ed3A4d8f2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0',
    sortOrder: 999,
    isFinished: false,
  },
  // {
  //   // SwapRewardsChef
  //   sousId: 2,
  //   stakingToken: tokens.mtrl,
  //   earningToken: tokens.mtrl,
  //   contractAddress: {
  //     369: '0x217da50b13682e74b519b3bb94b586d964e5f3ba',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '0',
  //   sortOrder: 999,
  //   isFinished: true,
  // },
  // {
  //   // PhantzCarrotStake
  //   sousId: 3,
  //   stakingToken: tokens.carrot,
  //   earningToken: tokens.carrot,
  //   contractAddress: {
  //     369: '0x8991F7B2e66D2211bCDB1A1796f39A4699b8631a',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   tokenPerBlock: '0',
  //   sortOrder: 999,
  //   isFinished: true,
  // },
  // {
  //   // PhantzCarrotStakeV2
  //   sousId: 4,
  //   stakingToken: tokens.carrot,
  //   earningToken: tokens.carrot,
  //   contractAddress: {
  //     369: '0x6be4661405096e1dEEC1eD03250bB9d38aeA0804',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   tokenPerBlock: '0',
  //   sortOrder: 999,
  //   isFinished: true,
  // },
  // {
  //   sousId: 8,
  //   stakingToken: tokens.carrot,
  //   earningToken: tokens.mtrl,
  //   contractAddress: {
  //     369: '0x34e940EC7622b96faE4E0F472ba5b5925e8b9f59',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   tokenPerBlock: '0.96450617',
  //   sortOrder: 999,
  //   isFinished: false,
  // },
  // {
  //   sousId: 6,
  //   stakingToken: tokens.carrot,
  //   earningToken: tokens.elk,
  //   contractAddress: {
  //     369: '0x7c7707Cb9396fbF5c33a13128eFA66A5B0d96788',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   tokenPerBlock: '0.00868',
  //   sortOrder: 999,
  //   isFinished: false,
  // },
  // {
  //   sousId: 7,
  //   stakingToken: tokens.carrot,
  //   earningToken: tokens.oelk,
  //   contractAddress: {
  //     369: '0xEB56783E9df8F3051771CFCE2FBD0E96C34c03E0',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   tokenPerBlock: '0.00868',
  //   sortOrder: 999,
  //   isFinished: true,
  // },
  // {
  //   sousId: 8,
  //   stakingToken: tokens.carrot,
  //   earningToken: tokens.mtrl,
  //   contractAddress: {
  //     369: '0x010C5C7d921d47a75051EE2381be76f9b77b869E',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   tokenPerBlock: '0.7233796',
  //   sortOrder: 999,
  //   isFinished: true,
  // },
  // {
  //   sousId: 9,
  //   stakingToken: tokens.carrot,
  //   earningToken: tokens.elk,
  //   contractAddress: {
  //     369: '0x00B7724512b909d6d41EAEef70A735eDB5617Dc8',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   tokenPerBlock: '0.00635',
  //   sortOrder: 999,
  //   isFinished: true,
  // },
  // {
  //   sousId: 10,
  //   stakingToken: tokens.carrot,
  //   earningToken: tokens.mtrl,
  //   contractAddress: {
  //     369: '0x4c1f6477cd444d4d78f37af1518548f06289e394',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   tokenPerBlock: '0.7716',
  //   sortOrder: 999,
  //   isFinished: true,
  // },
  // {
  //   sousId: 11,
  //   stakingToken: tokens.carrot,
  //   earningToken: tokens.mtrl,
  //   contractAddress: {
  //     369: '0x211c2B77fb7baA412005DBb004AC75e185EceDB3',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   tokenPerBlock: '0.41795',
  //   sortOrder: 999,
  //   isFinished: true,
  // },
]

export default pools
