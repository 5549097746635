import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'CARROT',
    lpAddresses: {
      369: '0x3B196427969452e3a4DE6D612B320343A5e91166',
    },
    token: tokens.radish,
    quoteToken: tokens.wpulse,
  },
  {
    pid: 1,
    lpSymbol: 'CARROT-PULSE LP',
    lpAddresses: {
      369: '0x58E1fF4B9cad5f91aa495E27b6417e8a5474B66B',
    },
    token: tokens.carrot,
    quoteToken: tokens.wpulse,
  },
  {
    pid: 2,
    lpSymbol: 'USDC-PULSE LP',
    lpAddresses: {
      369: '0x0A9451062902c989EB026Dba12B0abFb7a66177A',
    },
    token: tokens.usdc,
    quoteToken: tokens.wpulse,
  },
  // {
  //   pid: 3,
  //   lpSymbol: 'CARROT-USDC LP',
  //   lpAddresses: {
  //     369: '0xC0d42ce755DC0D693a4E5480B11dc2e3bD171959',
  //   },
  //   token: tokens.carrot,
  //   quoteToken: tokens.usdc,
  // },
  // {
  //   pid: 13,
  //   lpSymbol: 'stPULSE-PULSE LP',
  //   lpAddresses: {
  //     369: '0xE1349b31D91C1E00175343df9E60a1981086787A',
  //   },
  //   token: tokens.stela,
  //   quoteToken: tokens.wpulse,
  // },

  // {
  //   pid: 3,
  //   lpSymbol: 'ETH-PULSE LP',
  //   lpAddresses: {
  //     369: '0xa86883c2405f4557D2242Df47b220C54d0D611e4',
  //   },
  //   token: tokens.eth,
  //   quoteToken: tokens.wpulse,
  // },
  // {
  //   pid: 4,
  //   lpSymbol: 'HUSD-USDC LP',
  //   lpAddresses: {
  //     369: '0xB0917F2595A2c4C56498f6da2C52690a3EF558D2',
  //   },
  //   token: tokens.husd,
  //   quoteToken: tokens.usdc,
  // },
  // {
  //   pid: 5,
  //   lpSymbol: 'HT-PULSE LP',
  //   lpAddresses: {
  //     369: '0xC6734784EE598855200dABC8D8B1fA1F11f14C90',
  //   },
  //   token: tokens.ht,
  //   quoteToken: tokens.wpulse,
  // },
  // {
  //   pid: 6,
  //   lpSymbol: 'htFILDA-PULSE LP',
  //   lpAddresses: {
  //     369: '0x5B0Cf7D3b2D6885e1173674f4649B914e7A66B96',
  //   },
  //   token: tokens.htfilda,
  //   quoteToken: tokens.wpulse,
  // },
  // {
  //   pid: 8,
  //   lpSymbol: 'MTRL-PULSE LP',
  //   lpAddresses: {
  //     369: '0x825872e7AB3EE8ABf1f1239711375e4F2b587220',
  //   },
  //   token: tokens.mtrl,
  //   quoteToken: tokens.wpulse,
  // },
  // {
  //   pid: 9,
  //   lpSymbol: 'oELK-CARROT LP',
  //   lpAddresses: {
  //     369: '0x707A778354639EaAE03E739d2D5E8Db96CA30bF7',
  //   },
  //   token: tokens.oelk,
  //   quoteToken: tokens.carrot,
  // },
  // {
  //   pid: 10,
  //   lpSymbol: 'BUSD-USDC LP',
  //   lpAddresses: {
  //     369: '0x9ad2439CaE9440427f3dFF53A11A57A5a7211152',
  //   },
  //   token: tokens.busd,
  //   quoteToken: tokens.usdc,
  // },
  // {
  //   pid: 11,
  //   lpSymbol: 'BNB-PULSE LP',
  //   lpAddresses: {
  //     369: '0x421C1960169a50a963Cd8317d10b96298CaD0dc6',
  //   },
  //   token: tokens.bnb,
  //   quoteToken: tokens.wpulse,
  // },
  // {
  //   pid: 12,
  //   lpSymbol: 'ELK-CARROT LP',
  //   lpAddresses: {
  //     369: '0x59aDb497691A71831cDC07258ccCAa5294E68996',
  //   },
  //   token: tokens.elk,
  //   quoteToken: tokens.carrot,
  // }
]

export default farms
