// import BigNumber from 'bignumber.js'
// import { getCakeVaultContract } from 'utils/contractHelpers'
import { getMaterialPoolContract, getMaterialContract } from 'utils/contractHelpers'

// const cakeVaultContract = getCakeVaultContract()
const materialPoolContract = getMaterialPoolContract()
const carrotContract = getMaterialContract()

const fetchMaterialPoolUser = async (account: string) => {
  try {
    const carrotAllowance = (await carrotContract.allowance(account, materialPoolContract.address)).toString()
    const carrotBalance = (await carrotContract.balanceOf(account)).toString()
    const carrotStaked = (await materialPoolContract.userInfo(account))[0].toString()
    const carrotPending = (await materialPoolContract.pendingRewards(account)).toString()

    return {
      isLoading: false,
      allowance: carrotAllowance,
      stakingTokenBalance: carrotBalance,
      materialStakedBalance: carrotStaked,
      pendingReward: carrotPending,
    }
  } catch (error) {
    return {
      isLoading: true,
      allowance: null,
      stakingTokenBalance: null,
      materialStakedBalance: null,
      pendingReward: null,
    }
  }
}

export default fetchMaterialPoolUser
